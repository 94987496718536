import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard/Dashboard");
const ClassList = () => import("@/views/Dashboard/School/ClassList");
const DivisionList = () => import("@/views/Dashboard/School/DivisionList");
const YearList = () => import("@/views/Dashboard/School/YearList");
const Teacher = () => import("@/views/Dashboard/Teacher/Teacher");
const AddTeacher = () => import("@/views/Dashboard/Teacher/AddTeacher");
const TeacherList = () => import("@/views/Dashboard/Teacher/TeacherList");
const AddTeacherMap = () => import("@/views/Dashboard/Teacher/AddTeacherMap");
const Student = () => import("@/views/Dashboard/Student/Student");
const AddStudent = () => import("@/views/Dashboard/Student/AddStudent");
const StudentList = () => import("@/views/Dashboard/Student/StudentList");
const AddStudentMap = () => import("@/views/Dashboard/Student/AddStudentMap");
const Districts = () => import("@/views/Dashboard/Districts/Districts");
const School = () => import("@/views/Dashboard/School/School");
const AddSchool = () => import("@/views/Dashboard/School/AddSchool");
const SubjectList = () => import("@/views/Dashboard/School/SubjectList");
const AddClass = () => import("@/views/Dashboard/School/AddClass");
const AddDivision = () => import("@/views/Dashboard/School/AddDivision");
const AddSubject = () => import("@/views/Dashboard/School/AddSubject");
const AddYear = () => import("@/views/Dashboard/School/AddYear");

// Student
const profile = () => import("@/views/student/profile");
const student_dashboard = () => import("@/views/student/student_dashboard");
const upload_material = () => import("@/views/student/upload_material");
const my_uploads = () => import("@/views/student/my_uploads");
const student_register = () => import("@/views/student/student_register");
const details = () => import("@/views/student/details");

// Teacher
const teacher_profile = () => import("@/views/Teacher/teacher_profile");
const teacher_study_material = () => import("@/views/Teacher/teacher_study_material");
const add_study_content = () => import("@/views/Teacher/add_study_content");
const student_list = () => import("@/views/Teacher/student_list");
// const student_dashboard = () => import("@/views/student/student_dashboard");
// const upload_material = () => import("@/views/student/upload_material");

// School
const teacherClass = () => import("@/views/mapping/teacherClass");
const teacher_class = () => import("@/views/mapping/teacher_class");
const student_class = () => import("@/views/mapping/student_class");
const studentClass = () => import("@/views/mapping/studentClass");


// upload

//const UploadStudy = () => import("@/views/Study/UploadStudy");
const UploadStudy1 = () => import("@/views/Study/UploadStudy1");



// Auth
const Login = () => import("@/Auth/Login/Login");
const Register = () => import("@/Auth/Register/Register");

const Colors = () => import("@/views/theme/Colors");
const Typography = () => import("@/views/theme/Typography");

const Charts = () => import("@/views/charts/Charts");
const Widgets = () => import("@/views/widgets/Widgets");

// Views - Components
const Cards = () => import("@/views/base/Cards");
const Forms = () => import("@/views/base/Forms");
const Switches = () => import("@/views/base/Switches");
const Tables = () => import("@/views/base/Tables");
const Tabs = () => import("@/views/base/Tabs");
const Breadcrumbs = () => import("@/views/base/Breadcrumbs");
const Carousels = () => import("@/views/base/Carousels");
const Collapses = () => import("@/views/base/Collapses");
const Jumbotrons = () => import("@/views/base/Jumbotrons");
const ListGroups = () => import("@/views/base/ListGroups");
const Navs = () => import("@/views/base/Navs");
const Navbars = () => import("@/views/base/Navbars");
const Paginations = () => import("@/views/base/Paginations");
const Popovers = () => import("@/views/base/Popovers");
const ProgressBars = () => import("@/views/base/ProgressBars");
const Tooltips = () => import("@/views/base/Tooltips");

// Views - Buttons
const StandardButtons = () => import("@/views/buttons/StandardButtons");
const ButtonGroups = () => import("@/views/buttons/ButtonGroups");
const Dropdowns = () => import("@/views/buttons/Dropdowns");
const BrandButtons = () => import("@/views/buttons/BrandButtons");

// Views - Icons
const CoreUIIcons = () => import("@/views/icons/CoreUIIcons");
const Brands = () => import("@/views/icons/Brands");
const Flags = () => import("@/views/icons/Flags");

// Views - Notifications
const Alerts = () => import("@/views/notifications/Alerts");
const Badges = () => import("@/views/notifications/Badges");
const Modals = () => import("@/views/notifications/Modals");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
// const Login = () => import("@/views/pages/Login");
// const Register = () => import("@/views/pages/Register");

// Users
const Users = () => import("@/views/users/Users");
const User = () => import("@/views/users/User");

Vue.use(Router);



const router = new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
})



// export default new Router({
//   mode: "hash", // https://router.vuejs.org/api/#mode
//   linkActiveClass: "active",
//   scrollBehavior: () => ({ y: 0 }),
//   routes: configRoutes(),
// });



function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/student_register",
      name: "Home",
      component: TheContainer,
      // children: [
      //   {
      //     path: "dashboard",
      //     name: "Dashboard",
      //     component: Dashboard,
      //     meta: {
      //       authRequires: true
      //     }

      //   },

      //   {
      //     path: "dashboard",
      //     redirect: "/dashboard",
      //     name: "Dashboard",
      //     component: {
      //       render(c) {
      //         return c("router-view");
      //       },
      //     },


      //     children: [

      //       {
      //         path: "teacher",
      //         name: "Teacher",
      //         component: Teacher,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "addteacher",
      //         name: "AddTeacher",
      //         component: AddTeacher,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "teacherlist",
      //         name: "TeacherList",
      //         component: TeacherList,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "addteachermap",
      //         name: "AddTeacherMap",
      //         component: AddTeacherMap,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "student",
      //         name: "Student",
      //         component: Student,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "addstudent",
      //         name: "AddStudent",
      //         component: AddStudent,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "studentlist",
      //         name: "StudentList",
      //         component: StudentList,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "addstudentmap",
      //         name: "AddStudentMap",
      //         component: AddStudentMap,
      //         meta: {
      //           authRequires: true
      //         }
      //       },

      //       {
      //         path: "districts",
      //         name: "Districts",
      //         component: Districts,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "school",
      //         name: "School",
      //         component: School,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "addschool",
      //         name: "AddSchool",
      //         component: AddSchool,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "addclass",
      //         name: "AddClass",
      //         component: AddClass,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "adddivision",
      //         name: "AddDivision",
      //         component: AddDivision,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "addyear",
      //         name: "AddYear",
      //         component: AddYear,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "addsubject",
      //         name: "AddSubject",
      //         component: AddSubject,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "subjectlist",
      //         name: "SubjectList",
      //         component: SubjectList,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "divisionlist",
      //         name: "DivisionList",
      //         component: DivisionList,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //       {
      //         path: "classlist",
      //         name: "ClassList",
      //         component: ClassList,
      //         meta: {
      //           authRequires: true
      //         }
      //       },{
      //         path: "yearlist",
      //         name: "YearList",
      //         component: YearList,
      //         meta: {
      //           authRequires: true
      //         }
      //       },
      //     ],
      //   },

      //   /// study

      //   {
      //     path: "study",
      //     redirect: "/study/uploadstudy1",
      //     name: "Study",
      //     component: {
      //       render(c) {
      //         return c("router-view");
      //       },
      //     },
      //     children: [
      //       {
      //         path: "UploadStudy1",
      //         name: "UploadStudy1",
      //         component: UploadStudy1,
      //       },



      //     ],
      //   },

      //   {
      //     path: "theme",
      //     redirect: "/theme/colors",
      //     name: "Theme",
      //     component: {
      //       render(c) {
      //         return c("router-view");
      //       },
      //     },
      //     children: [
      //       {
      //         path: "colors",
      //         name: "Colors",
      //         component: Colors,
      //       },
      //       {
      //         path: "typography",
      //         name: "Typography",
      //         component: Typography,
      //       },
      //     ],
      //   },
      //   {
      //     path: "charts",
      //     name: "Charts",
      //     component: Charts,
      //   },
      //   {
      //     path: "widgets",
      //     name: "Widgets",
      //     component: Widgets,
      //   },
      //   {
      //     path: "users",
      //     meta: {
      //       label: "Users",
      //     },
      //     component: {
      //       render(c) {
      //         return c("router-view");
      //       },
      //     },
      //     children: [
      //       {
      //         path: "",
      //         name: "Users",
      //         component: Users,
      //       },
      //       {
      //         path: ":id",
      //         meta: {
      //           label: "User Details",
      //         },
      //         name: "User",
      //         component: User,
      //       },
      //     ],
      //   },
        // {
        //   path: "base",
        //   redirect: "/base/cards",
        //   name: "Base",
        //   component: {
        //     render(c) {
        //       return c("router-view");
        //     },
        //   },
      //     children: [
      //       {
      //         path: "cards",
      //         name: "Cards",
      //         component: Cards,
      //       },
      //       {
      //         path: "forms",
      //         name: "Forms",
      //         component: Forms,
      //       },
      //       {
      //         path: "switches",
      //         name: "Switches",
      //         component: Switches,
      //       },
      //       {
      //         path: "tables",
      //         name: "Tables",
      //         component: Tables,
      //       },
      //       {
      //         path: "tabs",
      //         name: "Tabs",
      //         component: Tabs,
      //       },
      //       {
      //         path: "breadcrumbs",
      //         name: "Breadcrumbs",
      //         component: Breadcrumbs,
      //       },
      //       {
      //         path: "carousels",
      //         name: "Carousels",
      //         component: Carousels,
      //       },
      //       {
      //         path: "collapses",
      //         name: "Collapses",
      //         component: Collapses,
      //       },
      //       {
      //         path: "jumbotrons",
      //         name: "Jumbotrons",
      //         component: Jumbotrons,
      //       },
      //       {
      //         path: "list-groups",
      //         name: "List Groups",
      //         component: ListGroups,
      //       },
      //       {
      //         path: "navs",
      //         name: "Navs",
      //         component: Navs,
      //       },
      //       {
      //         path: "navbars",
      //         name: "Navbars",
      //         component: Navbars,
      //       },
      //       {
      //         path: "paginations",
      //         name: "Paginations",
      //         component: Paginations,
      //       },
      //       {
      //         path: "popovers",
      //         name: "Popovers",
      //         component: Popovers,
      //       },
      //       {
      //         path: "progress-bars",
      //         name: "Progress Bars",
      //         component: ProgressBars,
      //       },
      //       {
      //         path: "tooltips",
      //         name: "Tooltips",
      //         component: Tooltips,
      //       },
      //     ],
      //   },
      //   {
      //     path: "buttons",
      //     redirect: "/buttons/standard-buttons",
      //     name: "Buttons",
      //     component: {
      //       render(c) {
      //         return c("router-view");
      //       },
      //     },
      //     children: [
      //       {
      //         path: "standard-buttons",
      //         name: "Standard Buttons",
      //         component: StandardButtons,
      //       },
      //       {
      //         path: "button-groups",
      //         name: "Button Groups",
      //         component: ButtonGroups,
      //       },
      //       {
      //         path: "dropdowns",
      //         name: "Dropdowns",
      //         component: Dropdowns,
      //       },
      //       {
      //         path: "brand-buttons",
      //         name: "Brand Buttons",
      //         component: BrandButtons,
      //       },
      //     ],
      //   },
      //   {
      //     path: "icons",
      //     redirect: "/icons/coreui-icons",
      //     name: "CoreUI Icons",
      //     component: {
      //       render(c) {
      //         return c("router-view");
      //       },
      //     },
      //     children: [
      //       {
      //         path: "coreui-icons",
      //         name: "Icons library",
      //         component: CoreUIIcons,
      //       },
      //       {
      //         path: "brands",
      //         name: "Brands",
      //         component: Brands,
      //       },
      //       {
      //         path: "flags",
      //         name: "Flags",
      //         component: Flags,
      //       },
      //     ],
      //   },
      //   {
      //     path: "notifications",
      //     redirect: "/notifications/alerts",
      //     name: "Notifications",
      //     component: {
      //       render(c) {
      //         return c("router-view");
      //       },
      //     },
      //     children: [
      //       {
      //         path: "alerts",
      //         name: "Alerts",
      //         component: Alerts,
      //       },
      //       {
      //         path: "badges",
      //         name: "Badges",
      //         component: Badges,
      //       },
      //       {
      //         path: "modals",
      //         name: "Modals",
      //         component: Modals,
      //       },
      //     ],
      //   },
      // ],
    },

    {
      path: "/auth",
      redirect: "/auth/login",
      name: "Auth",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "register",
          name: "Register",
          component: Register,
        },
      ],
    },

    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },

    // student
    {
      path: "/student",
      redirect: "/student/student_dashboard",
      name: "student_dashboard",
      component: TheContainer,
      // component: {
      //   render(c) {
      //     return c("router-view");
      //   },
      // },
      children: [
        {
          path: "/student_dashboard",
          name: "student_dashboard",
          component: student_dashboard,
        },
        {
          path: "/upload_material",
          name: "upload_material",
          component: upload_material,
        },
        {
          path: "/my_uploads",
          name: "my_uploads",
          component: my_uploads,
        },
        {
          path: "/student_register",
          name: "student_register",
          component: student_register,
        },
        {
          path: "/details/:subjectName",
          name: "details",
          component: details,
        },
        {
          path: "/profile",
          name: "profile",
          component: profile,
        },
      ],
    },

    // Teacher
    {
      path: "/teacher",
      redirect: "/student/dashboard",
      name: "dashboard",
      component: TheContainer,
      // component: {
      //   render(c) {
      //     return c("router-view");
      //   },
      // },
      children: [
        // {
        //   path: "/student_dashboard",
        //   name: "student_dashboard",
        //   component: student_dashboard,
        // },
        // {
        //   path: "/upload_material",
        //   name: "upload_material",
        //   component: upload_material,
        // },
        {
          path: "/teacher_profile",
          name: "teacher_profile",
          component: teacher_profile,
        },
        {
          path: "/teacher_study_material",
          name: "teacher_study_material",
          component: teacher_study_material,
        },
        {
          path: "/add_study_content",
          name: "add_study_content",
          component: add_study_content,
        },
        {
          path: "/student_list",
          name: "student_list",
          component: student_list,
        },
      ],
    },

    // School
    {
      path: "/school",
      redirect: "/student/dashboard",
      name: "dashboard",
      component: TheContainer,
      // component: {
      //   render(c) {
      //     return c("router-view");
      //   },
      // },
      children: [
        {
          path: "/teacherClass",
          name: "teacherClass",
          component: teacherClass,
        },
        {
          path: "/studentClass",
          name: "studentClass",
          component: studentClass,
        },
        {
          path: "/teacher_class",
          name: "teacher_class",
          component: teacher_class,
        },
        {
          path: "/student_class",
          name: "student_class",
          component: student_class,
        },
      ],
    },
  ];
}


let user_id = localStorage.getItem('user_id');
let user_role_id = localStorage.getItem('user_role_id');
console.log(user_id);
router.beforeEach((to, from, next) => {
  console.log(to)

  if (to.meta.authRequires == true) {
    if (user_id == undefined || user_id == null) {
      console.log("Not defined");
      ///return <Redirect to="/" />;
      //return '/';
      next('/auth');
    } else {
      if (user_role_id == '8290f938-b20a-42e3-c201-08d8f3241655') {
        next('/');
      } else {
        next();
      }

    }
  } else {
    next();
  }


});

export default router
